<template>
    <no-module/>
</template>

<script>
    import NoModule from "@/components/NoModule";
    export default {
        components: {NoModule}
    }
</script>
